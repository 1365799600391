import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  update(event) {
    console.log(event);
    const topic = event.currentTarget.dataset.filtersTopic;
    const subtopic = event.currentTarget.dataset.filtersSubtopic;
    const params = new URLSearchParams(window.location.search);

    if (topic) {
      params.set("topic_id", topic);
    }

    if (subtopic) {
      params.set("subtopic_id", subtopic);
    }

    // Update Turbo Frame source with new filters
    const turboFrame = document.getElementById("review_extracts_index");
    turboFrame.src = `/reports/topics/review_extracts?${params.toString()}`;
    turboFrame.reload();
  }
}
