import { Tabs } from "tailwindcss-stimulus-components";

export default class extends Tabs {
  connect() {
    super.connect();
    console.log("Tabs controller connected");
    this.selectTabFromParams();
  }

  updateParams(event) {
    event.preventDefault();
    const selectedTab = event.currentTarget.getAttribute("href").replace("#", "");
    
    // Update URL params instead of hash
    const url = new URL(window.location);
    url.searchParams.set("tab", selectedTab);
    history.replaceState(null, "", url);

    // Manually activate the tab
    this.selectTab(selectedTab);
  }

  selectTabFromParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const tabParam = urlParams.get("tab");

    if (!tabParam) return;

    const tab = this.tabTargets.find(t => t.getAttribute("href") === `#${tabParam}`);
    if (tab) {
      tab.click(); // Simulate a click to activate the tab
    }
  }
}
